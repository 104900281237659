<template>
  <div class="h-100 w-100 d-flex flex-column">
    <!-- <BaseLoading v-if="isLoading" nameLoading="loading-erp" :labelLoading="labelLoading" /> -->

    <div class="h-100 w-100 d-flex flex-column">
      <div
        class="container-fluid shadow-sm bg-white shadow-hover card-panel d-flex flex-column"
        style="height: -webkit-fill-available"
      >
        <Header />

        <hr />

        <Table />

        <Footer />
      </div>

      <ModalErros v-if="state.modalVisualizarErrosAberto" />

      <ModalFormulario v-if="state.modalFormularioNovaImportacaoAberto" />

      <ModalConfiguracoes v-if="state.modalDeConfiguracoesAberto" />
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';

  import swal from '@/common/alerts';

  import Header from '@/components/produtos/imagens/ProdutosImportacaoImagemHeader.vue';
  import Table from '@/components/produtos/imagens/ProdutosImportacaoImagemTable.vue';
  import Footer from '@/components/produtos/imagens/ProdutosImportacaoImagemFooter.vue';
  import ModalErros from '@/components/produtos/imagens/ProdutosImportacaoImagemModalErros.vue';
  import ModalFormulario from '@/components/produtos/imagens/ProdutosImportacaoImagemModalFormulario.vue';
  import ModalConfiguracoes from '@/components/produtos/imagens/ProdutosImportacaoImagemModalConfiguracoes.vue';

  export default {
    name: 'ProdutosImportacaoImagens',
    components: {
      Header,
      Table,
      Footer,
      ModalErros,
      ModalFormulario,
      ModalConfiguracoes,
    },
    computed: {
      ...mapState({
        state: (state) => state.imagens,
      }),
    },
    methods: {
      ...mapWaitingActions('imagens', {
        obterDadosIniciais: 'obterDadosIniciais imagens',
        executarConexaoHUB: 'executarConexaoHUB imagens',
      }),
      async obterDadosIniciaisImportacaoImagens() {
        try {
          await this.obterDadosIniciais();
        } catch (erro) {
          let mensagemErro = '';

          if (erro.response) {
            if (erro.response.status == 400) mensagemErro = erro.data;
            else mensagemErro = erro.message;
          } else mensagemErro = erro;

          swal.exibirMensagemErro(mensagemErro);
          return Promise.reject(mensagemErro);
        }
      },
      async executarConexaoHUBImportacaoImagens() {
        try {
          await this.executarConexaoHUB();
        } catch (erro) {
          let mensagemErro = '';

          if (erro.response) {
            if (erro.response.status == 400) mensagemErro = erro.data;
            else mensagemErro = erro.message;
          } else mensagemErro = erro;

          swal.exibirMensagemErro(mensagemErro);
          return Promise.reject(mensagemErro);
        }
      },
    },
    async mounted() {
      await this.executarConexaoHUBImportacaoImagens();
      await this.obterDadosIniciaisImportacaoImagens();
    },
    destroyed() {
      this.$store.commit('pararConexaoComHUB');
    },
  };
</script>

<style scoped></style>
