<template>
  <header>
    <div class="row">
      <div class="col-12">
        <h1 class="h1 lmx-tela-titulo d-block float-left"> IMPORTAÇÃO DE IMAGENS VTEX </h1>
        <span
          class="badge badge-secondary float-left lmx-bagde-guia-rapido-entrada-nfe d-none d-md-block"
          style="margin-top: 6px; margin-left: 6px"
        >
          <a
            href="https://share.linx.com.br/pages/viewpage.action?pageId=196831460"
            target="_blank"
            title="Precisa de ajuda? Acesso nosso Guia rápido!"
          >
            <i class="fas fa-book"></i>
            Guia Rápido
          </a>
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="input-group" @keypress.enter="filtrarImportacoes">
          <input
            id="input-filtrar-importacoes"
            type="text"
            class="form-control form-control-sm"
            placeholder="Filtre por alguma informação da listagem..."
            aria-label="Filtre por alguma informação da listagem..."
            autocomplete="off"
            v-model="filtroParaImportacoes"
          />
          <div class="input-group-append">
            <button id="filtrarImportacoes" class="btn btn-sm btn-primary" type="button" @click="filtrarImportacoes">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 d-flex justify-content-end">
        <button class="btn btn-success mr-1" @click="reniciarConexaoComHUB">
          <i class="fas fa-sync-alt"></i>
        </button>
        <button
          :disabled="existeAlgumaImportacaoEmProcessamento"
          class="btn btn-primary"
          @click="$store.commit('imagens/abrirModalFormularioNovaImportacao')"
        >
          <i class="fas fa-file-upload"></i>
          NOVA IMPORTAÇÃO
        </button>
      </div>
    </div>
  </header>
</template>

<script>
  import { mapWaitingActions } from 'vue-wait';
  import { mapGetters } from 'vuex';

  export default {
    name: 'ProdutosImportacaoImagemHeader',
    computed: {
      filtroParaImportacoes: {
        get() {
          return this.$store.state.imagens.filtroParaImportacoes;
        },
        set(value) {
          this.$store.commit('imagens/atualizarFiltroParaImportacoes', value);
        },
      },
      ...mapGetters('imagens', {
        existeAlgumaImportacaoEmProcessamento: 'existeAlgumaImportacaoEmProcessamento',
      }),
    },
    methods: {
      ...mapWaitingActions('imagens', {
        filtrarImportacoes: 'filtrarImportacoes imagens',
        reniciarConexaoComHUB: 'reniciarConexaoComHUB imagens',
      }),
    },
  };
</script>

<style></style>
