<template>
  <BaseModal
    @close="$store.commit('imagens/fecharModalFormularioNovaImportacao')"
    :classesAdicionaisModal="'modal m-3 rounded overflow-x-hidden w-90 w-sm-100 position-relative'"
    :tituloModal="'IMPORTAÇÃO DE IMAGENS VTEX'"
    :classesAdicionaisHeader="'d-flex align-items-center cabecalho-modal-customizado-lmx py-1 px-2 font-weight-bold'"
    :classesAdicionaisFooter="'p-0 bg-white d-flex justify-content-center align-items-center w-100'"
    :classesAdicionaisBody="'py-2 overflow-x-hidden'"
    id="modalImagemFormularioNovaImportaco"
    :telaInteira="true"
  >
    <template v-if="componenteCarregando" v-slot:loading>
      <BaseLoading nameLoading="loading-erp" :isLoadingFullScreen="false" :labelLoading="descricaoDoCarregamento" />
    </template>
    <template v-else v-slot:body>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 pt-4 pb-1">
            <div class="card mb-4">
              <div class="card-body">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-1">
                    <img src="@/assets/Images/linx-icon-ilustrativo/support.png" class="mx-auto d-block" />
                  </div>
                  <div class="col">
                    <p class="card-text">
                      Através desta rotina você poderá importar para os produtos do Microvix as imagens dos produtos cadastrados
                      no VTEX. Para começar, selecione abaixo a opção pela qual a busca das imagens será realizada. Essa opção
                      representa uma informação de produto que deve ser igual em ambas as plataformas.
                    </p>
                    <p class="card-text">
                      Atenção!<br />
                      Os produtos com imagens já cadastradas no Microvix terão sua imagem principal substituída pela imagem que
                      está sendo importada do VTEX.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row my-3">
              <div class="col-sm-12 col-md-6 col-xl-4 my-2">
                <label class="m-1 input-required">Tipo de Validação</label>
                <VueMultiselect
                  v-model="tipoValidacaoSelecionado"
                  :options="state.tiposValidacao"
                  track-by="id"
                  label="descricao"
                  placeholder="Selecione..."
                  :close-on-select="true"
                  :allow-empty="false"
                  :group-select="false"
                  :clear-on-select="true"
                  :preserve-search="false"
                  selected-label="Selecionado"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  :preselect-first="false"
                >
                  <span slot="noResult">Nenhum registro encontrado</span>
                  <span slot="noOptions">Selecione um registro</span>
                </VueMultiselect>
                <span v-if="!formularioValido" id="msg-erro-tipo-validacao" class="mensagem-erro">
                  Tipo Validação é um campo obrigatório
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="modal-footer d-flex w-100 p-0">
        <button class="btn btn-primary flex-grow-1" @click="iniciarExecucaoImportacao">
          <span>IMPORTAR</span>
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
  import VueMultiselect from 'vue-multiselect';
  import { mapState, mapGetters } from 'vuex';
  import { mapWaitingActions, mapWaitingGetters } from 'vue-wait';

  import swal from '@/common/alerts';

  export default {
    name: 'ProdutosImportacaoImagemModalFormulario',
    components: {
      VueMultiselect,
    },
    data() {
      return {
        formularioValido: true,
      };
    },
    computed: {
      ...mapState({
        state: (state) => state.imagens,
      }),
      ...mapGetters('imagens', {
        componenteCarregando: 'importacaoImagemModalFormularioCarregando',
        descricaoDoCarregamento: 'obterDescricaoDoCarregamento',
      }),
      tipoValidacaoSelecionado: {
        get() {
          return this.$store.state.imagens.tipoValidacaoSelecionado;
        },
        set(value) {
          this.$store.commit('imagens/atualizarTipoValidacaoSelecionado', value);
        },
      },
    },
    methods: {
      ...mapWaitingActions('imagens', {
        executarImportacao: 'executarImportacao imagens',
      }),
      validarCamposFormulario() {
        if (this.tipoValidacaoSelecionado) this.formularioValido = true;
        else this.formularioValido = false;
      },
      async iniciarExecucaoImportacao() {
        this.validarCamposFormulario();

        if (this.formularioValido) {
          try {
            await this.executarImportacao();
            swal.exibirToastSucesso('Importação iniciada com sucesso');
          } catch (erro) {
            let mensagemErro = '';

            if (erro.response) {
              if (erro.response.status == 400) mensagemErro = erro.data;
              else mensagemErro = erro.message;
            } else mensagemErro = erro;

            swal.exibirMensagemErro(mensagemErro);
            return Promise.reject(mensagemErro);
          } finally {
            this.formularioValido = true;
          }
        }
      },
    },
  };
</script>

<style>
  .mensagem-erro {
    font-size: 14px;
    color: red;
    padding: 0;
    margin: 0;
  }
</style>
