<template>
  <div class="row mt-3">
    <div class="col-12">
      <div class="d-flex justify-content-between">
        <div>
          <button
            class="btn btn-default"
            data-toggle="modal"
            @click="$store.commit('imagens/abrirModalConfiguracoes')"
            title="Abrir Configurações"
          >
            <i class="fa fa-cog"></i>
          </button>
        </div>
        <div>
          <button
            class="btn btn-secondary"
            :disabled="!botaoVisualizarErrosHabilitado"
            @click="$store.commit('imagens/abrirModalVisualizarErros')"
          >
            Visualizar erros
          </button>

          <button class="btn btn-secondary mx-2" :disabled="!botaoReimportarHabilitado" @click="executarReImportacaoVTEX"
            >Reimportar</button
          >

          <button class="btn btn-danger" :disabled="!importacaoSelecionadaProcessando" @click="cancelarImportacaoSelecionada"
            >Cancelar importação</button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapWaitingActions } from 'vue-wait';
  import { mapState, mapGetters } from 'vuex';
  import swal from '@/common/alerts';

  export default {
    name: 'ProdutosImportacaoImagemFooter',
    computed: {
      ...mapState({
        state: (state) => state.imagens,
      }),
      ...mapGetters('imagens', {
        importacaoSelecionadaPossuiErros: 'importacaoSelecionadaPossuiErros',
        importacaoSelecionadaConcluidaComInconsistencias: 'importacaoSelecionadaConcluidaComInconsistencias',
        importacaoSelecionadaProcessando: 'importacaoSelecionadaProcessando',
        importacaoSelecionadaCancelada: 'importacaoSelecionadaCancelada',
      }),
      botaoVisualizarErrosHabilitado() {
        return this.importacaoSelecionadaPossuiErros && !this.importacaoSelecionadaProcessando;
      },
      botaoReimportarHabilitado() {
        return !this.importacaoSelecionadaConcluidaComInconsistencias || !this.importacaoSelecionadaCancelada;
      },
    },
    methods: {
      ...mapWaitingActions('imagens', {
        cancelarImportacao: 'cancelarImportacao imagens',
        executarReImportacao: 'executarReImportacao imagens',
      }),
      async cancelarImportacaoSelecionada() {
        try {
          await this.cancelarImportacao();
          this.$store.commit('imagens/atualizarStatusImportacaoSelecionada', this.state.enumDeStatusImportacao.cancelado);
          swal.exibirToastSucesso('A importação foi cancelada com sucesso.');
        } catch (erro) {
          let mensagemErro = '';

          if (erro.response) {
            if (erro.response.status == 400) mensagemErro = erro.data;
            else mensagemErro = erro.message;
          } else mensagemErro = erro;

          swal.exibirMensagemErro(mensagemErro);
          return Promise.reject(mensagemErro);
        }
      },
      async executarReImportacaoVTEX() {
        try {
          await this.executarReImportacao();
          swal.exibirToastSucesso('A Reimportação foi iniciada com sucesso.');
        } catch (erro) {
          let mensagemErro = '';

          if (erro.response) {
            if (erro.response.status == 400) mensagemErro = erro.data;
            else mensagemErro = erro.message;
          } else mensagemErro = erro;

          swal.exibirMensagemErro(mensagemErro);
          return Promise.reject(mensagemErro);
        }
      },
    },
    mounted() {},
  };
</script>
<style></style>
