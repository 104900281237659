<template>
  <div class="h-100 w-100 overflow-hidden">
    <BaseLoading
      v-if="componenteCarregando"
      class="d-flex flex-column overflow-hidden h-100 w-100"
      nameLoading="loading-erp"
      :isLoadingFullScreen="false"
      :labelLoading="descricaoDoCarregamento"
    />

    <div v-else class="d-flex flex-column overflow-hidden h-100 w-100">
      <template v-if="existemImportacoesParaMostrar">
        <div class="row">
          <div class="col-12">
            <h5 class="h5" v-html="importacoesPossuemAlgumFiltro ? 'Resultados pesquisa' : 'Últimas Importações'"></h5>
          </div>
        </div>

        <div class="row overflow-hidden flex-grow-1 mb-1">
          <div class="col-12 w-100 h-100">
            <div class="h-100 w-100 d-flex flex-column">
              <div class="table-responsive-md overflow-auto flex-grow-1 p-0">
                <table class="table table-sm table-striped table-hover lmx-tabela-relatorio">
                  <thead class="importacao-table-header-style">
                    <tr>
                      <th>Data</th>
                      <th>Status</th>
                      <th>Registros</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="(importacao, index) in state.importacoesFiltradas"
                      :key="index"
                      @click="selecionarImportacao(importacao)"
                      :class="obterEstiloParaLinhaDaImportacaoSelecionada(importacao.id)"
                    >
                      <td class="align-middle">
                        {{ importacao.dataCriacao }}
                      </td>
                      <td class="align-middle">
                        <div class="d-flex">
                          {{ obterDescricaoStatusImportacao(importacao.idStatus) }}
                          <div class="ml-1" v-if="importacaoProcessando(importacao.idStatus)">
                            <i style="color: rgb(44, 0, 75); font-size: 15px" class="fas fa-circle-notch fa-spin fa-3x fa-fw"></i>
                          </div>
                        </div>
                      </td>
                      <td class="align-middle">
                        <span
                          class="badge badge-pill badge-success"
                          data-toggle="tooltip"
                          data-html="true"
                          title="Registros Criados"
                          >{{ importacao.quantidadeDeRegistrosImportados }}</span
                        >
                        <span
                          class="badge badge-pill badge-danger ml-1"
                          data-toggle="tooltip"
                          data-html="true"
                          title="Registros Divergentes"
                          >{{ importacao.quantidadeDeRegistrosComErros }}</span
                        >
                        <span
                          class="badge badge-pill badge-default ml-1"
                          data-toggle="tooltip"
                          data-html="true"
                          title="Total de Registros"
                          >{{ importacao.quantidadeTotalDeRegistros }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <small class="text-muted pl-0">
              Qtde. de Registros:
              <b>{{ quantidadeDeRegistrosFiltrados }}</b>
            </small>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="d-flex flex-grow-1 justify-content-center align-items-center w-100 h-100">
          <div class="text-center">
            <i class="fa fa-exclamation text-primary fa-4x"></i>
            <h3 style="color: #2c004b">Nenhuma importação encontrada!</h3>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';

  export default {
    name: 'ProdutosImportacaoImagemTable',
    filters: {},
    computed: {
      ...mapState({
        state: (state) => state.imagens,
      }),
      ...mapGetters('imagens', {
        componenteCarregando: 'importacaoImagemTableCarregando',
        descricaoDoCarregamento: 'obterDescricaoDoCarregamento',
        importacoesPossuemAlgumFiltro: 'importacoesPossuemAlgumFiltro',
        quantidadeDeRegistrosFiltrados: 'obterQuantidadeDeRegistrosFiltrados',
        obterDescricaoStatusImportacao: 'obterDescricaoStatusImportacao',
        importacaoProcessando: 'importacaoProcessando',
      }),
      existemImportacoesParaMostrar() {
        return this.quantidadeDeRegistrosFiltrados !== 0 && !this.componenteCarregando;
      },
    },
    methods: {
      selecionarImportacao(importacao) {
        const importacaoJaSelecionada = this.state.importacaoSelecionada && this.state.importacaoSelecionada.id === importacao.id;

        if (importacaoJaSelecionada) this.$store.commit('imagens/atualizarImportacaoSelecionada', null);
        else this.$store.commit('imagens/atualizarImportacaoSelecionada', importacao);
      },
      obterEstiloParaLinhaDaImportacaoSelecionada(idImportacao) {
        const importacaoSelecionada =
          this.state.importacaoSelecionada !== null && this.state.importacaoSelecionada.id === idImportacao;

        if (importacaoSelecionada) return 'importacao-selecionada';
      },
    },
  };
</script>

<style scoped>
  .importacao-selecionada {
    background-color: #ffb200 !important;
  }

  .lmx-tela-div-nenhum-registro {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .lmx-tela-div-nenhum-registro i {
    font-size: 3rem;
  }

  .importacao-table-header-style {
    background-color: #5b2e90;
    color: white;
    position: sticky;
    top: -0.1px;
    z-index: 1;
  }

  tr {
    cursor: pointer;
  }
</style>
