<template>
  <BaseModal
    id="modalImagemConfiguracoes"
    :classesAdicionaisModal="''"
    :classesAdicionaisHeader="'d-flex align-items-center cabecalho-modal-customizado-lmx font-weight-bold'"
    :classesAdicionaisFooter="'p-0'"
    :classesAdicionaisBody="obterEstiloDoBodyModal"
    :tituloModal="tituloModal"
    @close="$store.commit('imagens/fecharModalConfiguracoes')"
  >
    <template v-if="componenteCarregando" v-slot:loading>
      <BaseLoading
        nameLoading="loading-erp"
        :isLoadingFullScreen="false"
        :labelLoading="descricaoDoCarregamento"
        :isLoadingRunningOnModalWithVariableHeight="true"
      />
    </template>
    <template v-else v-slot:body>
      <div class="container-fluid">
        <form @submit.prevent="salvarConfiguracoes">
          <fieldset>
            <legend>Parâmetros</legend>

            <div class="row">
              <div class="col-12">
                <BaseInput
                  v-model="formulario.nomeDaConta"
                  label="Conta"
                  descricaoAuxiliar="Nome da conta, denominado accountName no VTEX."
                  type="text"
                  :erro="obterMensagemDeErroDoFormulario(['nomeDaContaObrigatorio'])"
                  :campoObrigatorio="true"
                  id="input-nome-da-conta"
                />
              </div>

              <div class="col-12 mt-2">
                <BaseInput
                  v-model="formulario.nomeDoAmbiente"
                  label="Ambiente"
                  descricaoAuxiliar="Nome do ambiente, denominado environment no VTEX."
                  type="text"
                  :erro="obterMensagemDeErroDoFormulario(['nomeDoAmbienteObrigatorio'])"
                  :campoObrigatorio="true"
                  id="input-nome-do-ambiente"
                />
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend>Autenticação</legend>

            <div class="row">
              <div class="col-12">
                <BaseInput
                  v-model="formulario.chave"
                  label="Chave"
                  descricaoAuxiliar="Código de identificação gerado pelo VTEX e denominado appKey."
                  type="text"
                  :erro="obterMensagemDeErroDoFormulario(['chaveObrigatorio'])"
                  :campoObrigatorio="true"
                  id="input-chave-vtex"
                />
              </div>

              <div class="col-12 mt-2">
                <BaseInput
                  v-model="formulario.token"
                  label="Token"
                  descricaoAuxiliar="Código de identificação gerado pelo VTEX e denominado appToken."
                  type="text"
                  :erro="obterMensagemDeErroDoFormulario(['tokenObrigatorio'])"
                  :campoObrigatorio="true"
                  id="input-token-vtex"
                />
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </template>

    <template v-slot:footer>
      <div class="modal-footer pr-4 bg-white">
        <button type="submit" class="btn btn-primary shadow-sm" @click="salvarConfiguracoes" :disabled="componenteCarregando">
          <span>SALVAR</span>
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';

  import swal from '@/common/alerts';

  export default {
    name: 'ProdutosImportacaoImagemModalConfiguracoes',
    data() {
      return {
        tituloModal: 'CONFIGURAÇÕES',
        errosFormulario: [],
        formulario: {
          nomeDaConta: '',
          nomeDoAmbiente: '',
          chave: '',
          token: '',
        },
      };
    },
    computed: {
      ...mapGetters('imagens', {
        componenteCarregando: 'importacaoImagemModalConfiguracoesCarregando',
        descricaoDoCarregamento: 'obterDescricaoDoCarregamento',
        dadosDeConfiguracao: 'obterDadosDeConfiguracao',
      }),
      obterEstiloDoBodyModal() {
        if (this.componenteCarregando) return 'imagem-modal-configuracoes-on-loading';
        else return '';
      },
    },
    watch: {
      dadosDeConfiguracao(newValue) {
        this.formulario = newValue;
      },
    },
    mounted() {
      this.formulario = this.dadosDeConfiguracao;
    },
    methods: {
      ...mapWaitingActions('imagens', {
        salvarDadosDeConfiguracao: 'salvarDadosDeConfiguracao imagens',
      }),
      async salvarConfiguracoes() {
        const formularioValido = this.validarFormulario();

        if (formularioValido) {
          try {
            const command = this.obterCommandDeEnvio();

            await this.salvarDadosDeConfiguracao(command);

            swal.exibirToastSucesso('Configurações cadastradas com sucesso!');
          } catch (erro) {
            let mensagemErro = '';

            if (erro.response) {
              if (erro.response.status == 400) mensagemErro = erro.data;
              else mensagemErro = erro.message;
            } else mensagemErro = erro;

            swal.exibirMensagemErro(mensagemErro);
            return Promise.reject(mensagemErro);
          }
        }
      },

      validarFormulario() {
        const { nomeDaConta, nomeDoAmbiente, chave, token } = this.dadosDeConfiguracao;

        if (!nomeDaConta) this.adicionarErroFormulario('nomeDaContaObrigatorio', 'Nome da conta é um campo obrigatório.');
        else this.removerErroFormulario('nomeDaContaObrigatorio');

        if (!nomeDoAmbiente)
          this.adicionarErroFormulario('nomeDoAmbienteObrigatorio', 'Nome do ambiente é um campo obrigatório.');
        else this.removerErroFormulario('nomeDoAmbienteObrigatorio');

        if (!chave) this.adicionarErroFormulario('chaveObrigatorio', 'Chave é um campo obrigatório.');
        else this.removerErroFormulario('chaveObrigatorio');

        if (!token) this.adicionarErroFormulario('tokenObrigatorio', 'Token é um campo obrigatório.');
        else this.removerErroFormulario('tokenObrigatorio');

        const formularioValido = this.errosFormulario.length === 0;

        return formularioValido;
      },
      obterCommandDeEnvio() {
        const command = {
          NomeDaConta: this.dadosDeConfiguracao.nomeDaConta,
          NomeDoAmbiente: this.dadosDeConfiguracao.nomeDoAmbiente,
          ChaveVTEX: this.dadosDeConfiguracao.chave,
          TokenVTEX: this.dadosDeConfiguracao.token,
        };

        return command;
      },

      adicionarErroFormulario(chaveErro, mensagem) {
        const erroJaExiste = this.errosFormulario.findIndex((erro) => erro.chave === chaveErro) !== -1;
        if (!erroJaExiste) this.errosFormulario.push({ chave: chaveErro, mensagem });
      },
      removerErroFormulario(chaveErro) {
        const indexErro = this.errosFormulario.findIndex((erro) => erro.chave === chaveErro);
        const erroExiste = indexErro !== -1;

        if (erroExiste) this.errosFormulario.splice(indexErro, 1);
      },
      obterMensagemDeErroDoFormulario(chavesErros) {
        let erroFormulario = {};

        const existeApenasUmErroNoArray = chavesErros.length === 1;

        if (existeApenasUmErroNoArray) erroFormulario = this.errosFormulario.find((erro) => erro.chave === chavesErros[0]);
        else {
          chavesErros.forEach((chave) => {
            if (!erroFormulario) erroFormulario = this.errosFormulario.find((erro) => erro.chave === chave);
          });
        }

        if (erroFormulario) return erroFormulario.mensagem;
        return null;
      },
    },
  };
</script>

<style scoped>
  legend {
    font-weight: 500;
    font-size: 1.5rem;
    font-family: 'Dosis', sans-serif;
    color: #333333;
  }

  fieldset {
    margin-bottom: 32px;
  }

  fieldset div {
    padding-left: 16px;
  }

  fieldset div div {
    border: 1px solid #e4e4e4;
    border-radius: 8px;
    padding: 14px 12px 18px 12px;
    box-sizing: border-box;
  }
</style>
