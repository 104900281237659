<template>
  <BaseModal
    @close="$store.commit('imagens/fecharModalVisualizarErros')"
    :classesAdicionaisModal="'rounded w-90 w-sm-100 h-100 position-relative modal-size-importacao'"
    :tituloModal="'VISUALIZAR INCONSISTÊNCIAS'"
    :classesAdicionaisHeader="'d-flex align-items-center cabecalho-modal-customizado-lmx py-1 px-2 font-weight-bold'"
    :classesAdicionaisFooter="'p-0'"
    :classesAdicionaisBody="'section-modal-importacao-produtos-style'"
    id="modalImagemVisualizarErros"
    :telaInteira="true"
  >
    <template v-if="componenteCarregando" v-slot:loading>
      <BaseLoading nameLoading="loading-erp" :isLoadingFullScreen="false" :labelLoading="descricaoDoCarregamento" />
    </template>

    <template v-else v-slot:body>
      <div class="container-fluid body-div-container-style">
        <div class="row mb-4">
          <div class="col-12 d-flex">
            <label class="font-weight-bold" style="font-size: 16px; margin-right: 4px">Importação: </label>
            <label class="text-uppercase flex-wrap" style="font-size: 14px; margin-top: 2px"> {{ descricaoImportacao }} </label>
          </div>
        </div>
        <div class="row body-div-row-overflow">
          <div class="col-12 d-flex justify-content-end align-items-end">
            <label class="registros-por-pagina-label">Registros por página</label>
            <select v-model="registrosPorPagina" class="form-control form-control-sm registros-por-pagina-select">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div class="col-12 body-div-table-overflow">
            <table class="table table-sm table-striped table-hover lmx-tabela table-bordered m-0">
              <thead class="tableFixHead">
                <tr>
                  <th class="px-2 min-width">Cód. Produto VTEX</th>
                  <th class="px-2 min-width">Cód. SKU VTEX</th>
                  <th class="px-2 min-width">Cód. Produto Microvix</th>
                  <th>Erros</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(erro, index) in listaDeErros.slice(indexInicial, indexFinal)" :key="index">
                  <td>{{ erro.codigoProdutoVtex }}</td>
                  <td>{{ erro.codigoSkuVtex }}</td>
                  <td>{{ erro.codigoProduto }}</td>
                  <td class="d-flex flex-column">
                    <span
                      v-for="(msgErro, indexErro) in erro.mensagens"
                      :key="'erro_' + index + '_' + indexErro"
                      v-html="msgErro"
                    ></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <span> {{ registrosComeco }} a {{ registrosAtuais }} de {{ registrosTotais }} registros</span>
          </div>
        </div>

        <div class="d-flex flex-row justify-content-center" v-if="totalPaginas > 1">
          <div v-for="(pagina, index) in totalPaginas" :key="index">
            <button
              v-if="verificarSeExibeBotaoPagina(pagina)"
              class="btn btn-default"
              :class="{
                active: pagina == paginacao.paginaAtual,
                'm-1': true,
              }"
              @click="paginacao.paginaAtual = pagina"
            >
              {{ pagina }}
            </button>
            <span v-else-if="verificarSeExibeBotaoPagina(pagina - 1)" class="m-1">...</span>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="modal-footer pr-4 bg-white">
        <button type="submit" class="btn btn-secondary shadow-sm" @click="$store.commit('imagens/fecharModalVisualizarErros')">
          <span>FECHAR</span>
        </button>
        <button
          type="submit"
          class="btn btn btn-primary shadow-sm"
          @click="obterArquivoDeErrosImportacaoSelecionada"
          :disabled="componenteCarregando"
        >
          <span>GERAR ARQUIVO</span>
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';

  import swal from '@/common/alerts';

  import { mapWaitingActions, mapWaitingGetters } from 'vue-wait';

  export default {
    name: 'ProdutosImportacaoImagemModalErros',
    data() {
      return {
        registrosPorPagina: 10,
        paginacao: {
          paginaAtual: 1,
        },
      };
    },
    computed: {
      ...mapState({
        state: (state) => state.imagens,
      }),
      ...mapGetters('imagens', {
        componenteCarregando: 'importacaoImagemModalVisualizarErrosCarregando',
        descricaoDoCarregamento: 'obterDescricaoDoCarregamento',
      }),
      totalPaginas() {
        if (this.state.errosImportacaoSelecionada)
          return Math.ceil(this.state.errosImportacaoSelecionada.erros.length / this.registrosPorPagina);
        else return 1;
      },
      indexInicial() {
        return this.registrosPorPagina * (this.paginacao.paginaAtual - 1);
      },
      indexFinal() {
        var indexFinal = Number(this.registrosPorPagina) * (this.paginacao.paginaAtual - 1) + Number(this.registrosPorPagina);

        return indexFinal;
      },
      registrosTotais() {
        if (this.state.errosImportacaoSelecionada) return this.state.errosImportacaoSelecionada.erros.length;
        else return 0;
      },
      registrosAtuais() {
        if (this.registrosTotais === 0) return 0;

        if (this.registrosTotais < this.registrosPorPagina) return this.registrosTotais;

        if (this.indexFinal > this.registrosTotais) return this.registrosTotais;

        return this.indexFinal;
      },
      registrosComeco() {
        if (this.registrosTotais === 0) return 0;

        if (this.registrosPorPagina > this.registrosTotais) return 1;

        return this.indexInicial + 1;
      },
      descricaoImportacao() {
        if (this.state.errosImportacaoSelecionada) return this.state.errosImportacaoSelecionada.descricaoImportacao;
        else return '';
      },
      listaDeErros() {
        if (this.state.errosImportacaoSelecionada) return this.state.errosImportacaoSelecionada.erros;
        else return [];
      },
    },
    watch: {
      totalPaginas(newVal, oldVal) {
        if (this.paginacao.paginaAtual > newVal) this.paginacao.paginaAtual = 1;
      },
    },
    methods: {
      ...mapWaitingActions('imagens', {
        obterErros: 'obterErros imagens',
        obterArquivoDeErros: 'obterArquivoDeErros imagens',
      }),
      async obterErrosImportacaoSelecionada() {
        try {
          await this.obterErros();
        } catch (erro) {
          let mensagemErro = '';

          if (erro.response) {
            if (erro.response.status == 400) mensagemErro = erro.data;
            else mensagemErro = erro.message;
          } else mensagemErro = erro;

          swal.exibirMensagemErro(mensagemErro);
          return Promise.reject(mensagemErro);
        }
      },
      async obterArquivoDeErrosImportacaoSelecionada() {
        try {
          await this.obterArquivoDeErros();
        } catch (erro) {
          let mensagemErro = '';

          if (erro.response) {
            if (erro.response.status == 400) mensagemErro = erro.data;
            else mensagemErro = erro.message;
          } else mensagemErro = erro;

          swal.exibirMensagemErro(mensagemErro);
          return Promise.reject(mensagemErro);
        }
      },
      verificarSeExibeBotaoPagina(pagina) {
        return (
          this.paginacao.paginaAtual == pagina ||
          this.paginacao.paginaAtual + 1 == pagina ||
          this.paginacao.paginaAtual - 1 == pagina ||
          this.totalPaginas == pagina ||
          this.totalPaginas - 1 == pagina ||
          1 == pagina ||
          2 == pagina
        );
      },
    },
    async mounted() {
      await this.obterErrosImportacaoSelecionada();
    },
  };
</script>

<style scoped>
  .lmx-tabela thead,
  tfoot {
    background-color: #5b2e90;
    color: white;
  }

  .lmx-tabela tbody {
    font-size: 90%;
  }

  .lmx-tabela-linha-totais {
    color: #ffffff;
    font-weight: bold;
    background-color: #5b2e90;
  }

  .registros-por-pagina-label {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0 4px 0 0;
  }

  .registros-por-pagina-select {
    height: 100%;
    max-width: 67px;
  }

  .overflow-modal-erros {
    overflow: hidden !important;
  }

  .table-height {
    max-height: 100vh;
  }

  .body-div-container-style {
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    overflow: hidden !important;
    width: 100% !important;
  }

  .body-div-row-overflow {
    overflow: hidden !important;
    height: 100% !important;
  }

  .body-div-table-overflow {
    overflow: overlay !important;
    height: calc(100% - 31px);
  }

  .tableFixHead {
    position: sticky;
    top: -0.1px;
    z-index: 1;
  }

  .min-width {
    width: 1%;
    white-space: nowrap;
  }
</style>
